.trigger {
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    position: absolute;
    top: 65px;
    z-index: 1;
    left: -25px;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: auto;
    margin: 25px 14px;
    text-align: center;   
  }
  
  .site-layout .site-layout-background {
    background: var(--white-color);
    margin: 10px 20px;
    border-radius: 10px !important;
    margin-left: 6px !important;
  }
  
/* scrollbar */
.scroll-bar::-webkit-scrollbar-track
{
	border-radius: 8px;
	background-color: #EEEEEE;
}

.scroll-bar::-webkit-scrollbar
{
	width: 7px;
	background-color: #EEEEEE;
}

.scroll-bar::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	background-color: #BBBBBB;
}







  .ant-table-body::-webkit-scrollbar,
  .ant-table::-webkit-scrollbar,
  .ant-table-container::-webkit-scrollbar,
  .ant-table-content::-webkit-scrollbar {
    width: 7px;
	background-color: #EEEEEE;
  height: 5px; 
    border-radius: 8px;
  }
  
  .ant-table-body::-webkit-scrollbar-thumb,
  .ant-table::-webkit-scrollbar-thumb,
  .ant-table-container::-webkit-scrollbar-thumb,
  .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #BBBBBB;
  }

  .roundedImg{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
  }

  .btnCheck .ant-space-item{
    width: 100%;
  }

  .checkAll{
    background-color:#7fb691;
    padding:10px;
    border-radius:10px;
    height:42px;
    width:100%;
    display: block;
    color: var(--black-color);
  }

  .border-0-btn{
    border: 0px !important;
    padding: 0px !important;
  }

  .listitem{
    padding: 8px;
  }

  .listitem .ant-menu-submenu-title{
    color: var(--black-color) !important;
  }

  .listitem .ant-menu-item{
    margin-left: -5px !important;
    margin-right: 0px !important;
    width: 100% !important;
    border-radius: 10px !important;
    color: var(--black-color) !important;
    padding-left: 15px !important;
  }

  .listitem .ant-menu-sub{
    position: relative;
  }

  .listitem .ant-menu-sub .ant-menu-item{
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
  }


  .listitem .ant-menu-sub::before{
    content: "";
    position: absolute;
    left: 3px;
    top: -23px;
    height: 100%;
    background: #778593;
    width: 1px;
    border-bottom-left-radius: 30px;
    z-index: 1;
  }

  .listitem .ant-menu-sub .ant-menu-item{
    position: relative;
  }


  .listitem .ant-menu-sub .ant-menu-item::before{
    content: "";
    position: absolute;
    left: 0px;
    top: 12px;
    height: 10px;
    background-image: url(../../public/assets/images/radius.png);
    background-size: 100% 100%;
    width: 15px;
    border-radius: 12px;
  }

  .ant-menu-dark .ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected{
    background-color: transparent !important;
  }

  .ant-menu-dark .ant-menu-item-selected,
  .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-title{
    background-image: url('../../public/assets/icons/active-mask.png') !important;
    background-size: 4% 100% !important;
    background-position: left !important;
    background-repeat: no-repeat !important;
  }

  .ant-menu-dark .ant-menu-item-selected,
  .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-title{
    background-color: #E6FAFA !important;
    z-index: 111;
  }

  /* .ant-menu-dark .ant-menu-submenu{
    padding-left: 15px !important;
  } */

  .ant-menu-dark .ant-menu-submenu .ant-menu-sub .ant-menu-item-selected{
    background: none !important;
    color: var(--brand-color) !important;
  }

  .ant-menu-dark .ant-menu-submenu .ant-menu-sub span{
    padding-inline: 10px;
    border-radius: 8px;
  }

  .ant-menu-dark .ant-menu-submenu .ant-menu-sub .ant-menu-item-selected span{
    background-color: #fff;
  }

  .ant-menu-dark .ant-menu-submenu {
    margin-left: -10px;
  }

  .listitem li .ant-menu-title-content{
    font-size: 12px;
  }


  .ant-menu-dark,.ant-menu-dark>.ant-menu{
    background: #E6FAFA !important;
  }

  .ant-menu-inline .ant-menu-submenu-title{
    padding-left: 0px;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    /* border-left: 2px solid #778593; */
    background-color: transparent;
    margin-left: 20px;
    padding-left: 8px;
  }

  /* .ant-menu-submenu-open .ant-menu-submenu-title{
    background-color: var(--secondary-color) !important;
  } */

  .ant-menu-submenu-title{
    background-color: transparent;
    padding-left: 15px !important;
    border-radius: 8px !important;
  }

  /* .ant-menu-dark>.ant-menu-submenu-popup .ant-menu-vertical{
    background: #fff !important;
  } */