.custom-date-picker{
    padding: 0px !important;
    border: 0px;
}
/* .custom-date-picker .ant-picker-input>input {
    display: none;
} */

.custom-date-picker .ant-picker-suffix{
    margin: 0px;
}

.custom-date-picker .ant-picker-input{
    flex-direction: row-reverse;
    gap: 10px;
    align-items: end;
}