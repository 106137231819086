.login-form-cover{
    background-color: var(--secondary-color);
    height: 100vh;
}

.login-form-covers{
    background-color: var(--secondary-color);
    padding-block: 20px;
}

.login-form{
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    width: 100%;
    max-width: 1000px;
}
.login-form-left-side{
    position: relative;
    width: calc(100% - 470px);
    background-color: var(--white-color);
    background-image: url('../../../public/assets/images/login-left.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.login-form-left-side div:last-child{
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.login-form-right-side{
    width: 470px;
    background: var(--white-color);
}

.ant-input-suffix .anticon svg{
    color: var(--black-color) !important;
}

@media only screen and (max-width: 976px) {
    .login-form{
        width: 93%;
    }
    /* .login-form img{max-width: 400px;} */
}

@media (max-width: 1000px){
    .login-form-left-side{
        display: none !important;
    }
    .login-form{
        justify-content: center;
    }
    .mb-5{
        margin-bottom: 3rem;
    }
}


